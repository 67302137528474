footer {
  display: flex; 
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;

  a {
    padding: 1rem 2rem;
    text-transform: lowercase;
  }

  .emoji {
    margin: 3rem 0 1rem;
    font-size: 2rem;
    display: block;
    animation: scale 2s ease-in-out;
    &:hover {
      transform: scale(1.1)
    }
  }


  @media (max-width: 600px) {
    padding: 1rem 0 3rem;
    
    a {
      display: block;
      text-align: center;
      padding: 0.4rem;
    }
  }
}