.info-page {
  max-width: 940px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;

  @media(max-width: 650px) {
    padding: 1rem;
  }

  h1 {
    margin-bottom: 3rem;
  }

  .button-container {
    display: flex;
    justify-content: center;
    padding-top: 3rem;

    .button {
      display: inline-block;
      padding: 0.8rem 2.6rem;
    }
  }
  
  .page-grid {
    display: flex;
    
    @media(max-width: 600px) {
      align-items: center;
      flex-direction: column;
    }
    
    .column {
      width: 50%;
      text-align: center;

      @media(max-width: 650px) {
        width: 100%;
      }
      
      .row {}
    }

    &.about {
      p {
        line-height: 1.2rem;
      }
    }
  }

  .not-found-gif {
    width: 100%;
    max-width: 40rem;
    margin-bottom: 2rem;
  }
}

/* @media (max-width: 768px) {
  .about-page {
    padding: 1.5rem;
  } */

  /* .about-content h1 {
    font-size: 2rem;
  } */

  /* .about-image {
    float: none;
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 0 auto 2rem;
  } */