.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.9); */
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.lightbox-container {
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.lightbox-close {
  position: absolute;
  top: -30px;
  right: 0;
  background: transparent;
  border: none;
  color: #a3a3a3;
  font-size: 1.8rem;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1100;
  line-height: 1;
  transition: 0.3s ease-in-out;

  &:hover {
    color: #171717;
    transform: scale(1.1);
  }
}

.lightbox-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: inherit;
  height: -webkit-fill-available;
}

.lightbox-image-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.lightbox-nav {
  position: absolute;
  background-color: rgb(163, 163, 163, 0.4);
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:  0.3s;
  z-index: 1050;
}

.lightbox-nav:hover {
  background-color: rgb(163, 163, 163, 0.1);
  transform: scale(1.1);
  color: #a3a3a3;
}

.lightbox-prev {
  left: 20px;
}

.lightbox-next {
  right: 20px;
}

.lightbox-counter {
  text-align: center;
  margin-top: 0.9rem;
  font-size: 0.7rem;
}

/* Animation for image transitions */
.lightbox-image {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .lightbox-nav {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }

  .lightbox-prev {
    left: 5px;
  }

  .lightbox-next {
    right: 5px;
  }

  .lightbox-close {
    top: -30px;
    font-size: 1.5rem;
  }
}