.project-page {
  margin: 0 auto;
  padding: 2rem;
}

.project-header {
  margin-bottom: 2rem;
}

.project-header h1 {
  /* font-size: 2.5rem; */
  /* font-weight: 500; */
  /* margin: 0; */
  /* color: #333; */
}

.project-header .project-category {
  margin-top: 0.5rem;
  letter-spacing: 0.05em;
}

.project-main-image {
  width: 100%;
  margin-bottom: 3rem;
  border-radius: 4px;
  overflow: hidden;
}

.project-main-image img {
  width: 100%;
  height: auto;
  display: block;
}

.project-content {
  display: grid;
  grid-template-columns: 5fr 2fr;
  gap: 3rem;
  margin-bottom: 3rem;


}

.project-description p {
  margin-bottom: 1rem;
}

.project-description img {
  width: 100%;
}

.meta-section {
  margin-bottom: 2rem;
}

.meta-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.meta-section li {
  margin-bottom: 0.4rem;
  color: #666;
  background-color: hsla(0, 0%, 96%, 0.8);
  border-radius: 4px;
  padding: 0.2rem .6rem;
  width: fit-content;
  display: inline-block;
  margin-right: 0.3rem;
  letter-spacing: 0.02rem;
}

/* 16:9 */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 3rem;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.project-image-gallery {
  margin-bottom: 2rem;

  .projecct-image-gallery-masonry-grid-column {
    padding-left: 1rem;
  }

  img {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/* Lightbox trigger cursor styling */
.lightbox-trigger {
  cursor: zoom-in;
}

.navigation-links {}

.project-buttons {
  height: 4.6rem;
}

.loader-container {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading,
.error {
  text-align: center;
  padding: 4rem 0;
  color: #666;
  font-size: 1.2rem;
}

.loader {
  width: 50px;
  /* margin: 0 auto; */
  height: fit-content;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: conic-gradient(#25b09b 25%, #f03355 0 50%, #514b82 0 75%, #ffa516 0);
  animation: l22 2s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 15%;
  border-radius: 50%;
  background: inherit;
  animation: inherit;
}

.loader::after {
  margin: 25%;
  animation-duration: 3s;
}

@keyframes l22 {
  100% {
    transform: rotate(1turn)
  }
}

@media (max-width: 768px) {
  .project-page {
    padding: 1.5rem;
  }

  /* .project-header h1 {
    font-size: 2rem;
  } */

  .project-content {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    gap: 1rem;
  }

  .project-main-image {
    margin-bottom: 2rem;
  }
}