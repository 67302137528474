.projects-page {
  margin: 0 auto;
  padding: 0 2rem;
  margin-left: -1.4rem;
}
  
.masonry-grid {
  display: flex;
  width: 100%;
}

.masonry-grid-column {
  padding-left: 1.4rem;
  background-clip: padding-box;
}

@media (max-width: 768px) {
  .projects-page {
    padding: 0 1rem;
  }
}