* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #333333;
  line-height: 1.5;
  font-size: .875rem;
  line-height: 1.25rem;
  letter-spacing: 0.01rem;
}

.app {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;

@media (max-width: 500px) {
    padding: 0rem;
  }
}

.main-content {
  margin-bottom: 2rem;
}

/* Typography */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}

h1 {
  line-height: 2.2rem;

  @media(max-width: 650px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  /* @media(max-width: 500px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  } */
}

h2 {
  font-weight: 1.5rem;
  margin-bottom: 2rem;
}

h3 {
  font-weight: 400;
  color: #999;
  text-transform: lowercase;
  letter-spacing: 0.05em;
  font-size: inherit;
  margin-bottom: 0.4rem;
}

ul {
  padding-inline-start: revert;
  margin-bottom: 1rem;
}

p, li {
  font-size: 1rem;
  line-height: 1.5rem;

  & b {
    font-weight: 500;
  }
}

a {
  color: inherit;
  text-decoration: none;
  color: #a3a3a3;
  transition: ease-in 0.2s;

  &.text-link {
    font-size: 16px;
    color: #333333;
    text-decoration: underline;
    display: block;
    transition: ease-out 0.2s;

    &:hover {
      color: #757575;
    }
  }
    
  &:hover {
    color: #171717;
    transition: ease-out 0.2s;
  }

  &.button {
    padding: 0.8rem;
    /* font-weight: 500; */
    color: #757575;
    display: block;
    text-align: center;
    border: 1px solid #e5e7eb;
    border-radius: .6rem;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: hsla(0, 0%, 100%, .95);
    transition: ease-out 0.2s;

    &:hover {
      background-color: hsla(0, 0%, 96%, 0.8);
      color: #171717;
    }
  }
}

/* Utility Classes */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.text-center {
  text-align: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  will-change: opacity, transform;
}

/* Performance optimizations */
.hardware-accelerated {
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

/* Basic Responsive Rules */
@media (max-width: 1024px) {
  /* Tablet styles */
}

@media (max-width: 768px) {
  /* Mobile styles */
}