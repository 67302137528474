.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 2rem;
  z-index: 1;
  margin: 1rem 0 2rem;
  letter-spacing: 0.02rem;
  text-transform: lowercase;

  @media (max-width: 500px) {
    margin: 2rem 0 1rem;
    padding: 0 1rem;
  }

  .main-nav {
    display: flex;
    justify-content: space-between;
  }

  .categories-nav {
    display: flex;
    width: fit-content;
    gap: 0.2rem;
    padding: .25rem;
    padding: .25rem;
    border: 1px solid #e5e7eb;
    border-radius: .6rem;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: hsla(0, 0%, 100%, .95);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);

    @media (max-width: 400px) {
      justify-content: space-between;

      .title {
        display: none;
      }
    }

    @media (max-width: 365px) {
      #cv {
        display: none;
      }
    }
  }

  .hover-box {
    position: absolute;
    background-color: hsla(0, 0%, 96%, 0.8);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    transition: left 0.2s ease, width 0.2s ease;
    z-index: -1;
  }

  .categories-nav a,
  .secondary-nav a {
    padding: 0.2rem .6rem;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    transition: ease-in 0.2s;

    svg {
      margin-bottom: -2px;

      @media (max-width: 365px) {
        display: none;
      }
    }

    &:hover {
      color: #171717;
      transition: ease-out 0.2s;
    }

    @media (max-width: 500px) {
      padding: 0.2rem 0.2rem;
      white-space: nowrap;
    }
  }

  .categories-nav a.active,
  .secondary-nav a.active {
    color: #171717;
  }

  h1.title {
    font-size: .875rem;
    margin: 0;
    transition: ease-in 0.2s;
  }

  h1.title span {
    color: #a3a3a3;
  }

  .secondary-nav {
    display: flex;
    align-items: center;
    transition: ease-in 0.2s;
  }

  .hide {
    opacity: 0;
    transition: ease-out 0.2s;
    transform: translateY(0.3rem);
  }
}