.project-card {
  margin-bottom: 1.4rem;
  opacity: 0;
  z-index: 1;

  @media (max-width: 500px) {
    margin-bottom: 1rem;
  }

  a {
    display: block;
    text-decoration: none;
  }
}

.project-card.visible {
  opacity: 1;
  transition: 0.25s ease-in; /* fade in when seen*/
  z-index: 1;
}

/* Unqiue look for the web app cards */
/* Don't know if I'm a fan */
/* .project-card.web-apps {
  --shadow: rgba(0, 0, 0, 0.25) 1px 11px 15px 10px;
  --shadow-hover: rgba(0, 0, 0, 0.25) 1px 11px 20px 5px;

  img {
    transform: scale(.91) translateY(2.3rem);
    box-shadow: var(--shadow);
    -webkit-box-shadow: var(--shadow);
    -moz-box-shadow: var(--shadow);
    transition: 0.1s ease-in;
  }
  &:hover {
    img {
      transform: scale(.95) translateY(2rem);
      box-shadow: var(--shadow-hover);
      -webkit-box-shadow: var(--shadow-hover);
      -moz-box-shadow: var(--shadow-hover);
    }
  }
} */

.project-image-container {
  overflow: hidden;
  border-radius: .4em;
  background-color: #f5f5f5;

  &.portraits {
    background-color: none;
    cursor: zoom-in;
  }
}

p.project-title {
  z-index: 2;
  position: absolute;
  margin: 0.5rem 0.8rem;
  font-size: 0.7rem;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.project-image-container img {
  width: 100%;
  border-radius: .4em;
  height: auto;
  display: block;
  transition: transform 0.16s ease-in-out;
  position: relative;
  z-index: 3;
}

.project-card:hover {
  &.ads,
  &.films,
  &.web-apps {
    .project-image-container img {
      transform: translateY(2.4rem);
    }
  }
  
  &.portraits .project-image-container img {
    transform: scale(1.03);
    cursor: zoom-in;
  }
}